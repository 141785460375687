import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import { Layout } from '../components/Layout'
import { Head } from '../components/Head'
import { font, color, mediaQuery, smartPhone } from '../constants/styles'

import { Link } from 'gatsby'

const displayNone = css`
  display: none;
`

const wrapper = css`
  font-size: ${font.textSize};
  width: 90%;
  margin: 0 auto;
`

const imageWrapper = css`
  width: 90%;
  margin: 0 auto;
`

const imageContainer = css`
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  padding: 56px 0 0 0;
  align-items: center;
  ${mediaQuery[smartPhone]} {
    width: 90%;
    margin: 0 auto;
    grid-template-columns: 1fr;
  }
`

const image = css`
  width: 90%;
  margin: 0 auto;
  max-width: 480px;
  ${mediaQuery[smartPhone]} {
    width: 90%;
    margin: 0 auto;
    width: 320px;
  }
`

const topTable = css`
  margin: 48px 0 48px 0;
`

const topContainer = css`
  display: grid;
  grid-template-columns: 160px 1fr;
`

const table = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
  ${mediaQuery[smartPhone]} {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
  margin: 0 0 48px 0;
`

const container = css`
  display: grid;
  grid-template-columns: 160px 1fr;
`

const noteContainer = css`
  margin: 0 0 48px 0;
  display: grid;
  grid-template-columns: 160px 1fr;
`

const title = css`
  background: ${color.mainD};
  padding: 16px;
  color: ${color.white};
  display: flex;
  align-items: center;
  border: solid 1px ${color.white};
  border-collapse: collapse;
  font-family: ${font.TitleFontFamily};
`

const text = css`
  padding: 16px;
  display: flex;
  align-items: center;
  border: solid 1px ${color.mainD};
  border-collapse: collapse;
`

const submitButtonContainer = css`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`

const submitButton = css`
  display: inline-flex;
  font-weight: Bold;
  border-radius: 4px;
  border: ${color.mainD};
  background-color: ${color.mainL};
  font-family: ${font.TitleFontFamily};
  color: ${color.white};
  padding: 30px 400px;
  font-size: ${font.subtitleSize};
  margin-bottom: 80px;
  :hover {
    background-color: ${color.mainD};
    cursor: pointer;
  }
  ${mediaQuery[smartPhone]} {
    padding: 24px 100px;
  }
`

export default function Details({ data }) {
  const {
    image1,
    image2,
    image3,
    image4,
    storeName,
    municipal,
    rent,
    management_expenses,
    security_deposit,
    key_money,
    deposit,
    renewal_fee,
    contract_period,
    type,
    building_structure,
    area,
    recruiting_area,
    built_year_month,
    facility,
    note,
  } = data.microcmsOpeninglist

  const pageTitle = `物件詳細｜${storeName}`
  const isImageNull1 = image1 === null
  const isImageNull2 = image2 === null
  const isImageNull3 = image3 === null
  const isImageNull4 = image4 === null
  return (
    <Layout>
      <Head props={pageTitle} />
      <div css={wrapper}>
        <div css={imageWrapper}>
          <div css={imageContainer}>
            <img
              src={isImageNull1 ? null : image1.url}
              alt="投稿画像1"
              css={isImageNull1 ? displayNone : image}
            />
            <img
              src={isImageNull2 ? null : image2.url}
              alt="投稿画像2"
              css={isImageNull2 ? displayNone : image}
            />
            <img
              src={isImageNull3 ? null : image3.url}
              alt="投稿画像3"
              css={isImageNull3 ? displayNone : image}
            />
            <img
              src={isImageNull4 ? null : image4.url}
              alt="投稿画像4"
              css={isImageNull4 ? displayNone : image}
            />
          </div>
        </div>
        <div css={topTable}>
          <div css={topContainer}>
            <div css={title}>病院名</div>
            <div css={text}>{storeName}</div>
          </div>
          <div css={topContainer}>
            <div css={title}>市町村</div>
            <div css={text}>{municipal}</div>
          </div>
        </div>
        <div css={table}>
          <div css={container}>
            <div css={title}>賃料</div>
            <div css={text}>{rent}</div>
          </div>
          <div css={container}>
            <div css={title}>管理費</div>
            <div css={text}>{management_expenses}</div>
          </div>
          <div css={container}>
            <div css={title}>保証金</div>
            <div css={text}>{security_deposit}</div>
          </div>
          <div css={container}>
            <div css={title}>礼金</div>
            <div css={text}>{key_money}</div>
          </div>
          <div css={container}>
            <div css={title}>敷金</div>
            <div css={text}>{deposit}</div>
          </div>
          <div css={container}>
            <div css={title}>償却・更新料</div>
            <div css={text}>{renewal_fee}</div>
          </div>
          <div css={container}>
            <div css={title}>契約期間</div>
            <div css={text}>{contract_period}</div>
          </div>
        </div>
        <div css={table}>
          <div css={container}>
            <div css={title}>物件種別</div>
            <div css={text}>{type}</div>
          </div>
          <div css={container}>
            <div css={title}>建物構造</div>
            <div css={text}>{building_structure}</div>
          </div>
          <div css={container}>
            <div css={title}>面積</div>
            <div css={text}>{area}</div>
          </div>
          <div css={container}>
            <div css={title}>募集区画</div>
            <div css={text}>{recruiting_area}</div>
          </div>
          <div css={container}>
            <div css={title}>築年月</div>
            <div css={text}>{built_year_month}</div>
          </div>
          <div css={container}>
            <div css={title}>設備</div>
            <div css={text}>{facility}</div>
          </div>
        </div>
        <div css={noteContainer}>
          <div css={title}>備考</div>
          <div css={text} dangerouslySetInnerHTML={{ __html: note }}></div>
        </div>
        <div css={submitButtonContainer}>
          <Link css={submitButton} to={`/OpeningList/`}>
            検索画面に戻る
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    microcmsOpeninglist(id: { eq: $id }) {
      image1 {
        url
      }
      image2 {
        url
      }
      image3 {
        url
      }
      image4 {
        url
      }
      municipal
      renewal_fee
      recruiting_area
      storeName
      security_deposit
      management_expenses
      rent
      key_money
      deposit
      contract_period
      building_structure
      built_year_month
      type
      area
      facility
      note
    }
  }
`
